export default class BlueBananaSerum {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  balanceOf() {
    return this.instance.balanceOf(this.address, 0);
  }
}
