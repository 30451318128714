import React, { useState } from "react";

import { FAQ_ITEMS } from "global_constants";
import { ReactComponent as XIcon } from "assets/close-icon.svg";

export default React.memo(function Faq() {
  const [expandItems, setExpandItems] = useState([]);

  function onToggleExpand(idx) {
    const index = expandItems.indexOf(idx);
    if (index > -1) {
      expandItems.splice(index, 1);
    } else {
      expandItems.push(idx);
    }
    setExpandItems([...expandItems]);
  }

  return (
    <div className="md:mt-32 mt-24" id="faq">
      <p className="text-6xl secondary-font uppercase mb-20 leading-tight">
        Frequently asked
        <br />
        questions
      </p>
      <div>
        {FAQ_ITEMS.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`faq-item bg-black ${
                idx !== FAQ_ITEMS.length - 1 ? "mb-4" : ""
              }`}
            >
              <div
                onClick={() => onToggleExpand(idx)}
                className="question flex cursor-pointer lg:py-16 lg:px-20 p-8 justify-between items-center"
              >
                <p
                  className="desktop secondary-font text-5xl uppercase leading-tight text-primary"
                  dangerouslySetInnerHTML={{ __html: item.question }}
                />
                <p
                  className="mobile secondary-font text-[20px] uppercase leading-tight text-primary"
                  dangerouslySetInnerHTML={{ __html: item.mobileQuestion }}
                />
                <XIcon />
              </div>
              <p
                className={`answer text-white lg:pt-12 lg:px-20 lg:pb-20 p-8 text-[18px] hidden ${
                  expandItems.indexOf(idx) > -1 ? "!block" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: item.answer }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});
