import { ERRORS } from "global_constants";

export const getEtherscanTxUrl = (hash) => `https://etherscan.io/tx/${hash}`;

export const getErrorMessage = (e) => {
  let msg = `An unexpected error occured and your transaction did not go through. 
  Please check your wallet or etherscan to see the reason of the failure.`;
  for (const error of ERRORS.MESSAGES) {
    if (e.message.includes(error.error)) {
      msg = error.message;
    }
  }

  return msg;
};
