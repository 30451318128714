import { ethers } from "ethers";
import { useEffect, useState } from "react";

import { getContractConfig } from "utils/contract";
import { CONTRACTS, NETWORK } from "global_constants";

export default function useProvider(provider) {
  const [contractsData, setContractsData] = useState({});

  useEffect(() => {
    if (provider) {
      (async function () {
        const data = {};
        const isCorrectChainId = provider.chainId === NETWORK.TARGET_CHAIN_ID;

        if (isCorrectChainId) {
          Object.keys(CONTRACTS).forEach((item) => {
            const config = getContractConfig(
              provider.chainId.toString(),
              CONTRACTS[item]
            );
            if (config) {
              data[CONTRACTS[item]] = new ethers.Contract(
                config.address,
                config.abi,
                provider.instance
              );
              data[CONTRACTS[item]].abi = config.abi;
            }
          });
        } else {
          alert(
            `Wrong network. Please connect to the ${
              NETWORK.CHAIN_ID_TO_NAME[NETWORK.TARGET_CHAIN_ID]
            } network.`
          );
          return;
        }

        setContractsData(data);
      })();
    }
  }, [provider]);

  return contractsData;
}
