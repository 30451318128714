/*eslint-disable*/
import React, { useReducer, useContext, createContext } from "react";

const ProviderContext = createContext();

export const ProviderProvider = ({ reducer, initialState, children }) => {
  const contextValue = useReducer(reducer, initialState);
  return (
    <ProviderContext.Provider value={contextValue}>
      {children}
    </ProviderContext.Provider>
  );
};

export const ProviderConsumer = () => {
  const contextValue = useContext(ProviderContext);
  return contextValue;
};
