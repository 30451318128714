export default class OmegaKongsClubMint {
  constructor(instance, address) {
    this.address = address;
    this.instance = instance;
  }

  getTokens() {
    return this.instance.getSerumKongsOfUser(this.address);
  }

  serumMint(signer, kongs) {
    return this.instance.connect(signer).serumMint(kongs);
  }

  kongToSerumStatus(kongId) {
    return this.instance.KongToSerumStatus(kongId);
  }
}
