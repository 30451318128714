import Portis from "@portis/web3";
import Authereum from "authereum";
import Fortmatic from "fortmatic";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { NETWORK } from "global_constants";

function getWalletConnectProvider() {
  return {
    package: WalletConnectProvider,
    options: {
      infuraId: NETWORK.INFURA_ID,
    },
  };
}

function getCustomWalletLinkProvider() {
  const coinbaseWallet = new CoinbaseWalletSDK({
    appName: "coinbase",
  });

  const ethereum = coinbaseWallet.makeWeb3Provider(
    `https://mainnet.infura.io/v3/${NETWORK.INFURA_ID}`,
    1
  );
  return {
    display: {
      name: "Coinbase",
      description: "Connect to Coinbase Wallet (not Coinbase App)",
      logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
    },
    package: ethereum,
    connector: async (provider, _options) => {
      await provider.enable();
      return provider;
    },
  };
}

function getPortisProvider() {
  return {
    display: {
      name: "Portis",
      description: "Connect to Portis App",
      logo: "https://user-images.githubusercontent.com/9419140/128913641-d025bc0c-e059-42de-a57b-422f196867ce.png",
    },
    package: Portis,
    options: {
      id: NETWORK.PORTIS_ID,
    },
  };
}

function getFormaticProvider() {
  return {
    package: Fortmatic, // required
    options: {
      key: NETWORK.FORTMATIC_ID, // required
    },
  };
}

export const getProviders = () => {
  const providerOptions = {
    authereum: {
      package: Authereum, // required
    },
    walletconnect: getWalletConnectProvider(),
    "custom-walletlink": getCustomWalletLinkProvider(),
  };

  if (NETWORK.PORTIS_ID && NETWORK.PORTIS_ID !== "") {
    providerOptions["portis"] = getPortisProvider();
  }

  if (NETWORK.FORTMATIC_ID && NETWORK.FORTMATIC_ID !== "") {
    //  Check if portis is correct
    providerOptions["formatic"] = getFormaticProvider();
  }

  return providerOptions;
};
